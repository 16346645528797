#footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  height: 500px;
  background-color: #171a29;
}
#footer img {
  max-width: 20%;
  max-height: 50%;
}
#footer p {
  color: #ECECEC;
}

@media screen and (max-width: 1000px) {
  #footer {
    height: 400px;
  }
  #footer img {
    max-width: 60%;
  }
}

