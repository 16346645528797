@import "../utils/colors";


.home_link {
    margin: 10px 25px;
    display: inline-block;
    position: relative;
    font-weight: bold;

    &:after {
        position: relative;
        content: '';
        width: 0;
        height: 2px;
        bottom: -2px;
        border-radius: 2px;
        background-color: $color-primary;
        margin: auto;
        display: block;
        transition: width 0.25s linear;
    }

    &:hover:after {
        width: 80%;
    }
}

@media screen and (max-width: 1000px) {
    .home_link {
        margin-top: 20px;

        p {
            font-size: 26px;
        }
    }
}