@import "../utils/colors";


#clubs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 50px;

    padding-top: 100px;
}

#clubs_logos {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    width: 85%;
}

.club_logo_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin: 20px;
    width: 200px;

    img {
        transition: all 0.1s linear;
        height: 110px;
        border-radius: 50%;
        margin: 5px;
    }

    &:hover {
        img {
            height: 120px;
            margin: 0;
        }
    }
}

@media screen and (max-width: 1200px) {
    #clubs_logos {
        width: 90%;
    }

    .club_logo_content {
        width: 80px;
        margin: 10px 30px;

        h6 {
            text-align: center;
        }
    }
}