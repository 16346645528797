#home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: fit-content;
  margin: 0;
  padding: 0;
}
#home:after {
  position: relative;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100px;
  background: linear-gradient(-180deg, rgba(236, 236, 236, 0), #ececec);
  display: block;
  margin: -100px 0;
  z-index: 2;
}

#home_background {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

#home_logo {
  position: fixed;
  top: 0;
  max-width: 40%;
  height: 100%;
  z-index: 0;
}

#home_background_front {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  position: absolute;
  z-index: 1;
}

@media screen and (max-width: 1000px) {
  #home {
    width: 100vw;
  }
  #home_background {
    height: 100vh;
    width: auto;
  }
  #home_background_front {
    height: 100vh;
    width: auto;
  }
  #home_logo {
    max-width: 80%;
  }
}

