#mentions_legales {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 100px 0;
}
#mentions_legales h1 {
  padding-bottom: 50px;
}
#mentions_legales h2 {
  padding: 20px;
}
#mentions_legales p {
  width: 60%;
  text-align: center;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 1000px) {
  #mentions_legales {
    width: 100%;
  }
  #mentions_legales h1 {
    text-align: center;
  }
  #mentions_legales h2 {
    padding: 20px;
    text-align: center;
  }
  #mentions_legales p {
    width: 60%;
    text-align: center;
    margin: 0;
    padding: 0;
  }
}

