.info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin: 0 50px;
}

.info_img {
    max-width: 100px;
    max-height: 75px;

    margin-right: 20px;
}

.info_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}


@media screen and (max-width: 1000px) {
    .info {
        justify-content: flex-start;
    }
}