#vie_associative {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 100px;
}

@media screen and (max-width: 1000px) {
  #vie_associative {
    width: 100vw;
  }
}

