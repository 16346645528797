#infos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 50px;
  width: 100%;
  margin-top: 100px;
  padding-top: 100px;
}
#infos p {
  width: 60%;
  text-align: center;
}

#infos_numbers {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media screen and (max-width: 1000px) {
  #infos {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
  }
  #infos p {
    width: 90%;
    margin-bottom: 20px;
  }
  #infos_numbers {
    width: fit-content;
    flex-direction: column;
    gap: 50px;
    align-items: stretch;
  }
}

