#mentions_legales {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    padding: 100px 0;

    h1 {
        padding-bottom: 50px;
    }

    h2 {
        padding: 20px;
    }

    p {
        width: 60%;
        text-align: center;
        margin: 0;
        padding: 0;
    }
}

@media screen and (max-width: 1000px) {
    #mentions_legales {
        width: 100%;

        h1 {
            text-align: center;
        }

        h2 {
            padding: 20px;
            text-align: center;
        }

        p {
            width: 60%;
            text-align: center;
            margin: 0;
            padding: 0;
        }
    }
}
