@import "../utils/colors";

#header {
    position: fixed;
    z-index: 5;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 60px;

    background: linear-gradient(180deg, rgba($color-background, 1), rgba($color-background, 0.6));

    &:after {
        position: absolute;
        top: 60px;
        left: 0;
        content: '';
        width: 100%;
        height: 90px;
        background: linear-gradient(180deg, rgba($color-background, 0.6), rgba($color-background, 0));
        margin: auto;
        display: block;
    }
}


#header_left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin-left: 10px;

    background-color: transparent;

    #header_icon {
        height: 40px;
    }
}

#header_right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    margin-right: 50px;

    background-color: transparent;
}

.header_extern_link {
    margin: 5px 5px;
    padding: 5px 20px;
    border: solid 2px $color-primary;
    border-radius: 30px;
    transition: all 0.2s linear;

    p {
        color: $color-primary;
        font-weight: bold;
    }

    &:hover {
        background-color: rgba($color-primary, 0.2);
    }
}

#menu_button {
    transition: all 0.2s ease-in-out;
}

#menu_hover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba($color-background, 0.8);

    transition: all 0.2s ease-in-out;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    #menu_hover_top {
        background: rgba($color-primary, 0);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        margin-bottom: 100px;

        img {
            height: 30px;
            z-index: 2;
            margin-top: 15px;
            margin-right: 20px;
        }
    }

}

@media screen and (max-width: 1000px) {
    #header_right {
        margin-right: 20px;

        img {
            height: 30px;
            z-index: 2;
        }
    }

    .header_extern_link {
        margin-top: 20px;

        p {
            font-size: 26px;
        }
    }



}