#header {
  position: fixed;
  z-index: 5;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  background: linear-gradient(180deg, #ececec, rgba(236, 236, 236, 0.6));
}
#header:after {
  position: absolute;
  top: 60px;
  left: 0;
  content: "";
  width: 100%;
  height: 90px;
  background: linear-gradient(180deg, rgba(236, 236, 236, 0.6), rgba(236, 236, 236, 0));
  margin: auto;
  display: block;
}

#header_left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  background-color: transparent;
}
#header_left #header_icon {
  height: 40px;
}

#header_right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 50px;
  background-color: transparent;
}

.header_extern_link {
  margin: 5px 5px;
  padding: 5px 20px;
  border: solid 2px #171a29;
  border-radius: 30px;
  transition: all 0.2s linear;
}
.header_extern_link p {
  color: #171a29;
  font-weight: bold;
}
.header_extern_link:hover {
  background-color: rgba(23, 26, 41, 0.2);
}

#menu_button {
  transition: all 0.2s ease-in-out;
}

#menu_hover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(236, 236, 236, 0.8);
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
#menu_hover #menu_hover_top {
  background: rgba(199, 12, 14, 0);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 100px;
}
#menu_hover #menu_hover_top img {
  height: 30px;
  z-index: 2;
  margin-top: 15px;
  margin-right: 20px;
}

@media screen and (max-width: 1000px) {
  #header_right {
    margin-right: 20px;
  }
  #header_right img {
    height: 30px;
    z-index: 2;
  }
  .header_extern_link {
    margin-top: 20px;
  }
  .header_extern_link p {
    font-size: 26px;
  }
}

