@import "../utils/colors";


#bureau {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    padding-top: 100px;
}

.bureau_content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 50px;
}

.bureau_photo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin: 20px 0;
    width: 300px;

    img {
        transition: all 0.1s linear;
        height: 110px;
        margin: 5px;
    }

    &:hover {
        img {
            height: 120px;
            margin: 0;
        }
    }
}

@media screen and (max-width: 1200px) {
    .bureau_content {
        width: 90%;
        flex-direction: row-reverse;
        align-items: flex-start;
        justify-content: center;
        gap: 0 20px;
    }

    .bureau_photo {
        width: 150px;

        h5 {
            text-align: center;
        }

        h6 {
            text-align: center;
        }
    }
}